import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from '@fca-app/auth/components/auth/auth.component';
import { ArenaListComponent } from '@fca-app/dashboard/components/home/arena-list/arena-list.component';
import { FighterDetailsComponent } from '@fca-app/dashboard/components/home/fighter-list/fighter-details/fighter-details.component';
import { FighterListComponent } from '@fca-app/dashboard/components/home/fighter-list/fighter-list.component';
import { ManageAdminsComponent } from '@fca-app/dashboard/components/manage/manage-admins/manage-admins.component';
import { ManageBannersComponent } from '@fca-app/dashboard/components/manage/manage-banners/manage-banners.component';
import { ManageComponent } from '@fca-app/dashboard/components/manage/manage.component';
import { DashboardMatchmakingComponent } from '@fca-app/dashboard/components/matchmaking/matchmaking.component';
import { StatisticsFeedbacksComponent } from '@fca-app/dashboard/components/statistics/statistics-feedbacks/statistics-feedbacks.component';
import { StatisticsUsersComponent } from '@fca-app/dashboard/components/statistics/statistics-users/statistics-users.component';
import { StatisticsVideoComponent } from '@fca-app/dashboard/components/statistics/statistics-video/statistics-video.component';
import { DashboardStatisticsComponent } from '@fca-app/dashboard/components/statistics/statistics.component';
import { CreateNewUserComponent } from '@fca-app/dashboard/components/users-management/create-new/create-new-user.component';
import { UsersManagementComponent } from '@fca-app/dashboard/components/users-management/users-management.component';
import { PermissionsErrorComponent } from '@fca-app/shared/components/permissions-error/permissions-error.component';
import { EAdminPermissions } from '@fca-app/shared/enums/admin-permissions.enum';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { IsAuthenticatedGuard } from '../auth/guards/is-authenticated.guard';
import { DashboardComponent } from './components/dashboard.component';
import { ArenaDetailsComponent } from './components/home/arena-list/arena-details/arena-details-component';

function routePermissionsData(only: string[]) {
    return {
        permissions: {
            only,
            redirectTo: '/dashboard/permissions-error',
        },
    };
}

const routes: Routes = [
    {
        path: 'dashboard',
        component: DashboardComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'arenas',
            },
            {
                path: 'permissions-error',
                component: PermissionsErrorComponent,
            },
            {
                path: 'users-management',
                component: UsersManagementComponent,
                children: [
                    {
                        path: 'create-user',
                        component: CreateNewUserComponent,
                        canActivate: [NgxPermissionsGuard],
                        data: routePermissionsData([EAdminPermissions.CreateNewUsers]),
                    },
                ],
            },
            {
                path: 'manage',
                component: ManageComponent,
                canActivate: [NgxPermissionsGuard],
                data: routePermissionsData([EAdminPermissions.SuperAdmin]),
                children: [
                    {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: 'admins',
                    },
                    {
                        path: 'admins',
                        component: ManageAdminsComponent,
                    },
                    {
                        path: 'banners',
                        component: ManageBannersComponent,
                    },
                ],
            },
            {
                path: 'statistics',
                component: DashboardStatisticsComponent,
                children: [
                    {
                        path: 'users',
                        component: StatisticsUsersComponent,
                        canActivate: [NgxPermissionsGuard],
                        data: routePermissionsData([EAdminPermissions.DownloadUsersStatistics]),
                    },
                    {
                        path: 'videos',
                        component: StatisticsVideoComponent,
                        canActivate: [NgxPermissionsGuard],
                        data: routePermissionsData([EAdminPermissions.DownloadUsersStatistics]),
                    },
                    {
                        path: 'feedbacks',
                        component: StatisticsFeedbacksComponent,
                        canActivate: [NgxPermissionsGuard],
                        data: routePermissionsData([EAdminPermissions.DownloadFeedbacksStatistics]),
                    },
                ],
            },
            {
                path: 'matchmaking/manage',
                component: DashboardMatchmakingComponent,
                canActivate: [NgxPermissionsGuard],
                data: routePermissionsData([EAdminPermissions.DownloadUsersStatistics]),
            },
            {
                path: 'arenas/:id',
                component: ArenaDetailsComponent,
                canActivate: [NgxPermissionsGuard],
                data: routePermissionsData([EAdminPermissions.ViewArenaDetails]),
            },
            {
                path: 'arenas',
                component: ArenaListComponent,
                canActivate: [NgxPermissionsGuard],
                data: routePermissionsData([EAdminPermissions.ViewArenas]),
            },
            {
                path: 'fighters',
                component: FighterListComponent,
                canActivate: [NgxPermissionsGuard],
                data: routePermissionsData([EAdminPermissions.ViewFighters]),
            },
            {
                path: 'fighters/:id',
                component: FighterDetailsComponent,
                canActivate: [NgxPermissionsGuard],
                data: routePermissionsData([EAdminPermissions.ViewFighterDetails]),
            },
        ],
        canActivate: [IsAuthenticatedGuard],
    },
    {
        path: 'login',
        component: AuthComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class DashboardRoutingModule {}
