import { BannerSlideItemModel, BannerSlideItemModelData } from '@fca-app/models/banners/banner-slide-item.model';
import { ModelFactory } from '@fca-app/shared/abstract/model-factory.abstract';
import { EFrontType } from '@fca-app/shared/enums/front-type.enum';
import { ELocale } from '@fca-app/shared/enums/locale.enum';

export class BannerSlideItemFactory extends ModelFactory<BannerSlideItemModelData, BannerSlideItemModel> {
    protected getInstance(): BannerSlideItemModel {
        return new BannerSlideItemModel();
    }

    getEmpty(): BannerSlideItemModel {
        return this.getModelFromData({
            id: null,
            image: '',
            needToOpenNewTab: false,
            destinationUrl: null,
            title: '',
            content: '',
            btnText: '',
            position: 0,
            platform: EFrontType.WEB,
            locale: ELocale.EN,
        });
    }
}
