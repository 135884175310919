export enum EAdminPermissions {
    CreateNewUsers = 'create_new_users',
    ViewUsers = 'view_users',
    EditUsers = 'edit_users',
    DownloadUsersStatistics = 'download_users_statistics',
    DownloadStatistics = 'download_users_statistics',
    DownloadFeedbacksStatistics = 'download_feedbacks_statistics',
    SuperAdmin = 'super_admin',
    ViewEvents = 'view_events',
    EditEvents = 'edit_events',
    BanUsers = 'ban_users',
    UnbanUsers = 'unban_users',
    MuteUsers = 'mute_users',
    UnmuteUsers = 'unmute_users',
    ViewArenas = 'view_arenas',
    ViewFighters = 'view_fighters',
    EditArenas = 'edit_arenas',
    ArenasAction = 'arenas_action',
    ViewArenaDetails = 'view_arena_details',
    ViewFighterDetails = 'view_fighter_details',
    AddFighterPenalties = 'add_fighter_penalties',
    RemoveFighterPenalties = 'remove_fighter_penalties',
    UploadFighterImages = 'upload_fighter_images',
    UploadArenaImages = 'upload_arena_images',
    EditArenaInfo = 'edit_arena_info',
    ManagePaymentEditRequests = 'manage_payment_edit_requests',
    ViewArenaEvents = 'view_arena_events',
    EditArenaEvent = 'edit_arena_event',
    SetCurrentlyFightSlot = 'set_currently_fight_slot',
    StartStopEvent = 'start_stop_event',
    ViewFighterAccountInfo = 'view_fighter_account_info',
    CompleteFighterPaymentRequest = 'complete_fighter_payment_request',
    MatchFighters = 'match_fighters',
    VerifyUser = 'verify-user',
    ManageBanners = 'manage-banners',
}
