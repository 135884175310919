import { EFrontType } from '@fca-app/shared/enums/front-type.enum';
import { ELocale } from '@fca-app/shared/enums/locale.enum';

export interface BannerSlideItemModelData {
    id: string | null;
    image: string;
    needToOpenNewTab: boolean;
    destinationUrl: string | null;
    title: string;
    content: string;
    btnText: string;
    position: number;
    locale: ELocale;
    platform: EFrontType;
}

export interface BannerSlideItemModel extends BannerSlideItemModelData {}

export class BannerSlideItemModel {}
