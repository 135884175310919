import { BannerDataResponse } from '@fca-app/api/fca/banners/interfaces/response/banner-data.response';
import { BannerSlideItemModelData } from '@fca-app/models/banners/banner-slide-item.model';
import { ModelDataMapper } from '@fca-app/shared/interfaces/model-data-mapper.interface';

export class BannerSlideItemMapper implements ModelDataMapper<BannerDataResponse, BannerSlideItemModelData> {
    mapData(raw: BannerDataResponse): BannerSlideItemModelData {
        return {
            id: raw.id,
            image: raw.image,
            needToOpenNewTab: raw.openNewTab,
            destinationUrl: raw.url,
            title: raw.data.title,
            content: raw.data.text,
            btnText: raw.data.btnText,
            position: raw.position,
            platform: raw.platform,
            locale: raw.locale,
        };
    }
}
